
import { ActionTypes } from "@/store/action-types";
import { MutationTypes } from "@/store/mutation-types";
import { NotificationItem, NotificationItemType } from "@/types/NotificationItem";
import { AnonymousUploadResult } from "@/types/SharedTypes";
import { DocumentAddIcon } from "@heroicons/vue/outline";
import axios, { AxiosError } from "axios";
import { nanoid } from "nanoid";
import { defineComponent } from "vue";

export default defineComponent({
    name: "UploadFile",
    components: {
        DocumentAddIcon,
    },
    data() {
        return { images: null };
    },
    methods: {
        addFileFromComputer() {
            const fileElement = this.$refs.file as HTMLInputElement;
            if (fileElement !== undefined && fileElement !== null && fileElement.files !== undefined && fileElement.files !== null) {
                fileElement.click();
            }
        },
        submitFile() {
            const anonymoussessionid = nanoid() + nanoid();
            const formData = new FormData();
            const fileElement = this.$refs.file as HTMLInputElement;
            if (fileElement && fileElement.files && fileElement.files.length > 0) {
                formData.append("file", fileElement.files[0]);
                const headers = { "Content-Type": "multipart/form-data", anonymoussessionid: anonymoussessionid };
                axios
                    .post(process.env.VUE_APP_API_URL + "Onboarding/upload", formData, { headers })
                    .then((e) => {
                        if (e.data.error !== null) {
                            this.$store.commit(MutationTypes.ADD_NOTIFICATION, new NotificationItem("Hata", e.data.error, NotificationItemType.Error));
                        } else {
                            const anonymousUploadResult = e.data.result as AnonymousUploadResult;
                            this.$store.commit(MutationTypes.SET_ANONYMOUSSESSIONID, anonymoussessionid);
                            this.$router.push("/templateDesigner/" + anonymoussessionid);
                        }
                    })
                    .catch((error: Error | AxiosError) => {
                        this.$store.dispatch(ActionTypes.HANDLEERROR, error);
                    })
                    .finally(() => {
                        // this.isWorking = false;
                    });
            }
        },
    },
});
