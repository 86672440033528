import { MutationTree } from "vuex";
import { MutationTypes } from "./mutation-types";
import { State } from "./state";
import { NotificationItem } from "@/types/NotificationItem";
import { nanoid } from "nanoid";

export type Mutations<S = State> = {
    [MutationTypes.ADD_NOTIFICATION](state: S, notificationItem: NotificationItem): void;
    [MutationTypes.CLOSE_NOTIFICATION](state: S, notificationItemId: string): void;
    [MutationTypes.SET_ANONYMOUSSESSIONID](state: S, anonyumousSessionId: string): void;
};

export const mutations: MutationTree<State> & Mutations = {
    [MutationTypes.ADD_NOTIFICATION](state, notificationItem: NotificationItem) {
        notificationItem.id = nanoid();
        state.notifications.push(notificationItem);
        setTimeout(() => {
            state.notifications.splice(
                state.notifications.findIndex((n) => n.id === notificationItem.id),
                1
            );
        }, 4000);
    },
    [MutationTypes.CLOSE_NOTIFICATION](state, notificationItemId: string) {
        const index = state.notifications.findIndex((n) => n.id === notificationItemId);
        if (index >= 0) state.notifications.splice(index, 1);
    },
    [MutationTypes.SET_ANONYMOUSSESSIONID](state, anonyumousSessionId: string) {
        state.anonyumousSessionId = anonyumousSessionId;
    },
};
