import { nanoid } from "nanoid";

export class NotificationItem {
    id: string;
    title: string;
    text: string;
    type: NotificationItemType;

    constructor(title: string, text: string, type: NotificationItemType) {
        this.id = nanoid();
        this.title = title;
        this.text = text;
        this.type = type;
    }
}

export enum NotificationItemType {
    Success = 1,
    Warning,
    Error,
    Info,
}

export interface RegisterParameters {
    email: string;
    fullname: string;
}
