import { NotificationItem } from "@/types/NotificationItem";
interface MyState {
    notifications: Array<NotificationItem>;
    anonyumousSessionId: string;
}

export const state: MyState = {
    notifications: [],
    anonyumousSessionId: "",
};

export type State = typeof state;
