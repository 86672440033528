
import { defineComponent } from "vue";
import { MutationTypes } from "./store/mutation-types";
import { NotificationItem, NotificationItemType } from "./types/NotificationItem";
import { CheckCircleIcon, ExclamationIcon, InformationCircleIcon, XIcon } from "@heroicons/vue/outline";

export default defineComponent({
    components: {
        CheckCircleIcon,
        XIcon,
        ExclamationIcon,
        InformationCircleIcon,
    },
    data() {
        return {
            show: true,
            NotificationItemType: NotificationItemType,
        };
    },
    mounted() {
        this.updateStyle();
        window.addEventListener("resize", this.updateStyle);
    },
    unmounted() {
        window.removeEventListener("resize", this.updateStyle);
    },
    methods: {
        updateStyle() {
            const t = this.$refs.mainDivInAppVue as InstanceType<typeof HTMLElement>;
            t.style.height = this.getWindowHeight() + "px";
        },
        getWindowHeight() {
            return window.innerHeight;
        },
        closeNotificationItem(id: string) {
            this.$store.commit(MutationTypes.CLOSE_NOTIFICATION, id);
        },
    },
    computed: {
        items(): Array<NotificationItem> {
            return this.$store.getters.getNotificationItems;
        },
    },
});
