
import { defineComponent } from "vue";
import UploadFile from "@/components/UploadFile.vue"; // @ is an alias to /src

export default defineComponent({
    name: "HomeView",
    components: {
        UploadFile,
    },
});
