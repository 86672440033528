import { GetterTree } from "vuex";
import { State } from "./state";
import { NotificationItem } from "@/types/NotificationItem";

export type Getters = {
    getNotificationItems(state: State): Array<NotificationItem>;
    getAnonymousSessionId(state: State): string;
};

export const getters: GetterTree<State, State> & Getters = {
    getNotificationItems: (state) => {
        return state.notifications;
    },
    getAnonymousSessionId: (state) => {
        return state.anonyumousSessionId;
    },
};
