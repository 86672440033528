import { ActionTree, ActionContext } from "vuex";
import { State } from "./state";
import { Mutations } from "./mutations";
import { ActionTypes } from "./action-types";

import axios from "axios";
import router from "@/router";
import { NotificationItem, NotificationItemType } from "@/types/NotificationItem";
import { MutationTypes } from "./mutation-types";

type AugmentedActionContext = {
    commit<K extends keyof Mutations>(key: K, payload: Parameters<Mutations[K]>[1]): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, State>, "commit">;

export interface Actions {
    [ActionTypes.RESET]({ commit }: AugmentedActionContext): Promise<boolean>;
    [ActionTypes.HANDLEERROR]({ commit }: AugmentedActionContext, error: Error): boolean;
}

export const actions: ActionTree<State, State> & Actions = {
    [ActionTypes.RESET]({ commit, state }) {
        return new Promise((resolve, reject) => {
            resolve(true);
        });
    },
    [ActionTypes.HANDLEERROR]({ commit }, error: Error) {
        if (axios.isAxiosError(error)) {
            if (error.response !== undefined && error.response !== null) {
                if (error.response.status === 401) {
                    commit(MutationTypes.ADD_NOTIFICATION, new NotificationItem("Hata", "Yetkisiz erişim. Giriş sayfasına yönlendirileceksiniz.", NotificationItemType.Error));
                    router.push("/login");
                } else if (error.response.status === 500) {
                    commit(MutationTypes.ADD_NOTIFICATION, new NotificationItem("Hata", "Sunucuda hata oluştu.", NotificationItemType.Error));
                } else if (error.response.status === 501) {
                    commit(MutationTypes.ADD_NOTIFICATION, new NotificationItem("Hata", "Bilinmeyen fonksiyon.", NotificationItemType.Error));
                } else {
                    commit(MutationTypes.ADD_NOTIFICATION, new NotificationItem("Hata", error.response.status.toString(), NotificationItemType.Error));
                }
            } else if (error.request !== undefined && error.request !== null) {
                commit(MutationTypes.ADD_NOTIFICATION, new NotificationItem("Hata", "Sunuculara erişilemedi.", NotificationItemType.Error));
            } else {
                if (error.message !== undefined && error.message !== null) {
                    commit(MutationTypes.ADD_NOTIFICATION, new NotificationItem("Hata", error.message, NotificationItemType.Error));
                } else {
                    commit(MutationTypes.ADD_NOTIFICATION, new NotificationItem("Hata", error.name, NotificationItemType.Error));
                }
            }
        } else {
            commit(MutationTypes.ADD_NOTIFICATION, new NotificationItem("Hata", error.message, NotificationItemType.Error));
        }
        return true;
    },
};
